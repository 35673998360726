/* Not really sure why, but the inverse version has a bigger gap */
/* line 42, ../src/scss/_mixins.scss */
.grow-from-left-enter {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    transition: -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8), -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
  }
  /* line 47, ../src/scss/_mixins.scss */
  .grow-from-left-enter.grow-from-left-enter-active {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  /* line 52, ../src/scss/_mixins.scss */
  .grow-from-left-leave {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    transition: -webkit-transform 0.15s ease-out;
    transition: transform 0.15s ease-out;
    transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
  }
  /* line 57, ../src/scss/_mixins.scss */
  .grow-from-left-leave.grow-from-left-leave-active {
    -webkit-transform: scale(0);
            transform: scale(0);
  }

  /* line 42, ../src/scss/_mixins.scss */
  .grow-from-right-enter {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0;
    transition: -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8), -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
  }
  /* line 47, ../src/scss/_mixins.scss */
  .grow-from-right-enter.grow-from-right-enter-active {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  /* line 52, ../src/scss/_mixins.scss */
  .grow-from-right-leave {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0;
    transition: -webkit-transform 0.15s ease-out;
    transition: transform 0.15s ease-out;
    transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
  }
  /* line 57, ../src/scss/_mixins.scss */
  .grow-from-right-leave.grow-from-right-leave-active {
    -webkit-transform: scale(0);
            transform: scale(0);
  }

  /* line 42, ../src/scss/_mixins.scss */
  .grow-from-center-enter {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 50% 0;
            transform-origin: 50% 0;
    transition: -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8), -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
  }
  /* line 47, ../src/scss/_mixins.scss */
  .grow-from-center-enter.grow-from-center-enter-active {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  /* line 52, ../src/scss/_mixins.scss */
  .grow-from-center-leave {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0;
            transform-origin: 50% 0;
    transition: -webkit-transform 0.15s ease-out;
    transition: transform 0.15s ease-out;
    transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
  }
  /* line 57, ../src/scss/_mixins.scss */
  .grow-from-center-leave.grow-from-center-leave-active {
    -webkit-transform: scale(0);
            transform: scale(0);
  }

  /* line 42, ../src/scss/_mixins.scss */
  .grow-from-up-left-enter {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
    transition: -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8), -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
  }
  /* line 47, ../src/scss/_mixins.scss */
  .grow-from-up-left-enter.grow-from-up-left-enter-active {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  /* line 52, ../src/scss/_mixins.scss */
  .grow-from-up-left-leave {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
    transition: -webkit-transform 0.15s ease-out;
    transition: transform 0.15s ease-out;
    transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
  }
  /* line 57, ../src/scss/_mixins.scss */
  .grow-from-up-left-leave.grow-from-up-left-leave-active {
    -webkit-transform: scale(0);
            transform: scale(0);
  }

  /* line 42, ../src/scss/_mixins.scss */
  .grow-from-up-center-enter {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    transition: -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8), -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
  }
  /* line 47, ../src/scss/_mixins.scss */
  .grow-from-up-center-enter.grow-from-up-center-enter-active {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  /* line 52, ../src/scss/_mixins.scss */
  .grow-from-up-center-leave {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    transition: -webkit-transform 0.15s ease-out;
    transition: transform 0.15s ease-out;
    transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
  }
  /* line 57, ../src/scss/_mixins.scss */
  .grow-from-up-center-leave.grow-from-up-center-leave-active {
    -webkit-transform: scale(0);
            transform: scale(0);
  }

  /* line 42, ../src/scss/_mixins.scss */
  .grow-from-up-right-enter {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    transition: -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8), -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
  }
  /* line 47, ../src/scss/_mixins.scss */
  .grow-from-up-right-enter.grow-from-up-right-enter-active {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  /* line 52, ../src/scss/_mixins.scss */
  .grow-from-up-right-leave {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    transition: -webkit-transform 0.15s ease-out;
    transition: transform 0.15s ease-out;
    transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
  }
  /* line 57, ../src/scss/_mixins.scss */
  .grow-from-up-right-leave.grow-from-up-right-leave-active {
    -webkit-transform: scale(0);
            transform: scale(0);
  }

  /* line 2, ../src/scss/_dd-menu.scss */
  .dd-menu {
    display: inline-block;
    position: relative;
  }
  /* line 6, ../src/scss/_dd-menu.scss */
  .dd-menu.dd-menu-center .dd-menu-items {
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  /* line 18, ../src/scss/_mixins.scss */
  .dd-menu.dd-menu-left .dd-menu-items {
    left: 0;
  }
  /* line 5, ../src/scss/_mixins.scss */
  .dd-menu.dd-menu-left .dd-menu-items [class^='nested-']:not(.nested-reverse) > span {
    right: 100%;
    padding-right: 0.5em;
  }
  /* line 10, ../src/scss/_mixins.scss */
  .dd-menu.dd-menu-left .dd-menu-items .nested-reverse > span {
    left: 100%;
    padding-left: 0.5em;
  }
  /* line 5, ../src/scss/_mixins.scss */
  .dd-menu.dd-menu-left.dd-menu-inverse .dd-menu-items [class^='nested-']:not(.nested-reverse) > span {
    right: 100%;
    padding-right: 0.3em;
  }
  /* line 10, ../src/scss/_mixins.scss */
  .dd-menu.dd-menu-left.dd-menu-inverse .dd-menu-items .nested-reverse > span {
    left: 100%;
    padding-left: 0.3em;
  }
  /* line 18, ../src/scss/_mixins.scss */
  .dd-menu.dd-menu-right .dd-menu-items {
    right: 0;
  }
  /* line 5, ../src/scss/_mixins.scss */
  .dd-menu.dd-menu-right .dd-menu-items [class^='nested-']:not(.nested-reverse) > span {
    left: 100%;
    padding-left: 0.5em;
  }
  /* line 10, ../src/scss/_mixins.scss */
  .dd-menu.dd-menu-right .dd-menu-items .nested-reverse > span {
    right: 100%;
    padding-right: 0.5em;
  }
  /* line 5, ../src/scss/_mixins.scss */
  .dd-menu.dd-menu-right.dd-menu-inverse .dd-menu-items [class^='nested-']:not(.nested-reverse) > span {
    left: 100%;
    padding-left: 0.3em;
  }
  /* line 10, ../src/scss/_mixins.scss */
  .dd-menu.dd-menu-right.dd-menu-inverse .dd-menu-items .nested-reverse > span {
    right: 100%;
    padding-right: 0.3em;
  }
  /* line 30, ../src/scss/_mixins.scss */
  .dd-menu.dd-menu-sm .dd-menu-items {
    width: 150px;
  }
  /* line 30, ../src/scss/_mixins.scss */
  .dd-menu.dd-menu-md .dd-menu-items {
    width: 300px;
  }
  /* line 30, ../src/scss/_mixins.scss */
  .dd-menu.dd-menu-lg .dd-menu-items {
    width: 450px;
  }
  /* line 30, ../src/scss/_mixins.scss */
  .dd-menu.dd-menu-xl .dd-menu-items {
    width: 600px;
  }
  /* line 15, ../src/scss/_dd-menu.scss */
  .dd-menu .dd-menu-items {
    position: absolute;
    z-index: 7;
    margin: 0.5em 0 0 0;
  }
  /* line 20, ../src/scss/_dd-menu.scss */
  .dd-menu .dd-menu-items.dd-items-upwards {
    bottom: 100%;
    margin: 0 0 0.5em;
  }
  /* line 25, ../src/scss/_dd-menu.scss */
  .dd-menu .dd-menu-items ul, .dd-menu .dd-menu-items ol {
    list-style: none;
    padding: 0;
    margin: 0;
    color: #000;
    background-color: #fefefe;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.29);
  }
  /* line 34, ../src/scss/_dd-menu.scss */
  .dd-menu .dd-menu-items ul.dd-items-right li > *, .dd-menu .dd-menu-items ol.dd-items-right li > * {
    text-align: right;
  }
  /* line 38, ../src/scss/_dd-menu.scss */
  .dd-menu .dd-menu-items ul.dd-items-left li > *, .dd-menu .dd-menu-items ol.dd-items-left li > * {
    text-align: left;
  }
  /* line 42, ../src/scss/_dd-menu.scss */
  .dd-menu .dd-menu-items ul.dd-items-center li > *, .dd-menu .dd-menu-items ol.dd-items-center li > * {
    text-align: center;
  }
  /* line 48, ../src/scss/_dd-menu.scss */
  .dd-menu .dd-menu-items ul li:hover:not([role="separator"]):not(.separator),
  /* .dd-menu .dd-menu-items ul li > *:focus,*/ .dd-menu .dd-menu-items ol li:hover:not([role="separator"]):not(.separator),
  .dd-menu .dd-menu-items ol li > *:focus {
    color: #000;
    background-color: #bdc3c7;
    outline: none;
  }
  /* line 55, ../src/scss/_dd-menu.scss */
  .dd-menu .dd-menu-items ul li > *:not(.dd-item-ignore), .dd-menu .dd-menu-items ol li > *:not(.dd-item-ignore) {
    display: block;
    width: auto;
    min-width: 100%;
    padding: 1em 1.5em;
    font-family: Roboto, sans-serif;
    white-space: pre;
    color: inherit;
    text-decoration: none;
    font-size: inherit;
  }
  /* line 68, ../src/scss/_dd-menu.scss */
  .dd-menu .dd-menu-items ul li > button, .dd-menu .dd-menu-items ol li > button {
    border: none;
    background: transparent;
  }
  /* line 78, ../src/scss/_dd-menu.scss */
  .dd-menu.dd-menu-inverse .dd-menu-items ul, .dd-menu.dd-menu-inverse .dd-menu-items ol {
    color: #fff;
    background-color: #2c3e50;
  }
  /* line 84, ../src/scss/_dd-menu.scss */
  .dd-menu.dd-menu-inverse .dd-menu-items ul li:hover:not([role="separator"]):not(.separator),
  .dd-menu.dd-menu-inverse .dd-menu-items ul li > *:focus, .dd-menu.dd-menu-inverse .dd-menu-items ol li:hover:not([role="separator"]):not(.separator),
  .dd-menu.dd-menu-inverse .dd-menu-items ol li > *:focus {
    color: #fff;
    background-color: #34495e;
  }
  /* line 93, ../src/scss/_dd-menu.scss */
  .dd-menu.dd-menu-inverse [role="separator"], .dd-menu.dd-menu-inverse .separator {
    background-color: #45595e;
  }
  /* line 98, ../src/scss/_dd-menu.scss */
  .dd-menu [role="separator"], .dd-menu .separator {
    content: '';
    display: block;
    height: 2px;
    background-color: rgba(0, 0, 0, 0.15);
    margin: 0.5em 0 0.5em 0;
  }
  /* line 107, ../src/scss/_dd-menu.scss */
  .dd-menu .dd-items-upwards li.nested-dd-menu > span {
    bottom: 0;
    top: initial;
  }
  /* line 113, ../src/scss/_dd-menu.scss */
  .dd-menu li.nested-dd-menu {
    position: relative;
  }
  /* line 116, ../src/scss/_dd-menu.scss */
  .dd-menu li.nested-dd-menu > span {
    position: absolute;
    top: 0;
  }

  /*# sourceMappingURL=react-dd-menu.css.map */
